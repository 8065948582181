import axios from 'axios';
import { OAT_AZURE_GROUPS } from '../constants';
import jwtDecode from 'jwt-decode';

export const fetchAzureGroups = async (token: string): Promise<string[]> => {
  const decodedToken: { oid: string } = jwtDecode(token);

  return await fetchAzureGroupsData(`https://graph.microsoft.com/v1.0/users/${decodedToken.oid}/memberOf`, token);
};

const fetchAzureGroupsData = async (url: string, token: string, groups: string[] = []): Promise<string[]> => {
  try {
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    for (const group of res.data.value) {
      if (OAT_AZURE_GROUPS.includes(group.displayName) && !groups.includes(group.displayName)) {
        groups.push(group.displayName);
      }
    }

    if (res.data['@odata.nextLink']) {
      return await fetchAzureGroupsData(`${res.data['@odata.nextLink']}`, token);
    }

    return groups;
  } catch (e) {
    console.log('error', (e as Error).message);
  }

  return groups;
};
