export enum AzureGroups {
  NATIONAL_TOYOTA_USER = "Toyota_Region_190",
  NATIONAL_LEXUS_USER = "Lexus_Region_390",
  REGIONAL_110_USER = "Toyota_Region_110",
  REGIONAL_120_USER = "Toyota_Region_120",
  REGIONAL_130_USER = "Toyota_Region_130",
  REGIONAL_150_USER = "Toyota_Region_150",
  REGIONAL_160_USER = "Toyota_Region_160",
  REGIONAL_170_USER = "Toyota_Region_170",
  REGIONAL_210_USER = "Toyota_Region_210",
  REGIONAL_220_USER = "Toyota_Region_220",
  REGIONAL_230_USER = "Toyota_Region_230",
  REGIONAL_500_USER = "Toyota_Region_500",
  REGIONAL_600_USER = "Toyota_Region_600",
  REGIONAL_800_USER = "Toyota_Region_800",
  REGIONAL_310_USER = "Lexus_Region_310",
  REGIONAL_320_USER = "Lexus_Region_320",
  REGIONAL_330_USER = "Lexus_Region_330",
  REGIONAL_340_USER = "Lexus_Region_340",
  TDA_SND_USER = "oat_snd_tda",
  TDA_SOC_USER = "oat_soc_tda",
  TDA_BOS_USER = "oat_bos_tda",
  TDA_CAT_USER = "oat_cat_tda",
  TDA_CHI_USER = "oat_chi_tda",
  TDA_CIN_USER = "oat_cin_tda",
  TDA_CON_USER = "oat_con_tda",
  TDA_DVR_USER = "oat_dvr_tda",
  TDA_GNY_USER = "oat_gny_tda",
  TDA_GOC_USER = "oat_goc_tda",
  TDA_GST_USER = "oat_gst_tda",
  TDA_INW_USER = "oat_inw_tda",
  TDA_KAC_USER = "oat_kac_tda",
  TDA_NCA_USER = "oat_nca_tda",
  TDA_PHX_USER = "oat_phx_tda",
  TDA_PTL_USER = "oat_ptl_tda",
  TDA_SET_USER = "oat_set_tda",
  TDA_TRE_USER = "oat_tre_tda",
  TDA_TRI_USER = "oat_tri_tda",
  TDA_UST_USER = "oat_ust_tda",
  TDA_WWN_USER = "oat_wwn_tda",
  LDA_LSA_USER = "oat_lsa_lda",
  LDA_LEA_USER = "oat_lea_lda",
  LDA_LWA_USER = "oat_lwa_lda",
  LDA_LCA_USER = "oat_lca_lda",
  TCUV_USER = "OAT_TCUV",
  LCERT_USER = "oat_lcert",
}

export const OAT_AZURE_GROUPS: string[] = Object.values(AzureGroups).filter(
  (val) => isNaN(Number(val))
);
