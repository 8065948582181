import { AzureGroups } from "../constants";

export const isNational = (userAzureGroups: string[]) => {
  if (
    userAzureGroups?.includes(AzureGroups.NATIONAL_TOYOTA_USER) ||
    userAzureGroups?.includes(AzureGroups.NATIONAL_LEXUS_USER)
  ) {
    return true;
  }
  return false;
};

export const isRegional = (userAzureGroups: string[]) => {
  const regionalGroups: string[] = Object.values(AzureGroups).filter(
    (group) =>
      (group.startsWith("Toyota_Region") &&
        group !== AzureGroups.NATIONAL_TOYOTA_USER) ||
      (group.startsWith("Lexus_Region") &&
        group !== AzureGroups.NATIONAL_LEXUS_USER)
  );

  if (
    userAzureGroups.filter((group) => regionalGroups.includes(group)).length > 0
  ) {
    return true;
  }
  return false;
};

export const isMarketing = (userAzureGroups: string[]) => {
  const marketingGroups: string[] = Object.values(AzureGroups).filter(
    (group) => group.endsWith("tda") || group.endsWith("lda")
  );

  if (
    userAzureGroups.filter((group) => marketingGroups.includes(group)).length >
    0
  ) {
    return true;
  }
  return false;
};

export const isCuv = (userAzureGroups: string[]) => {
  if (
    userAzureGroups?.includes(AzureGroups.TCUV_USER) ||
    userAzureGroups?.includes(AzureGroups.LCERT_USER)
  ) {
    return true;
  }
  return false;
};
