import { MaintenanceLayout, OATToast } from 'oat-common-ui';
import useGetToken from './hooks/useGetToken';
import useGetUserAzureGroups from './hooks/useGetUserAzureGroups';
import { isCuv, isMarketing, isNational, isRegional } from './utils/checkUserAzureGroup';

const App = () => {
  const token = useGetToken();
  const userAzureGroups = useGetUserAzureGroups(token);

  if (userAzureGroups?.length) {
    if (isNational(userAzureGroups)) {
      window.location.replace(process.env.REACT_APP_NATIONAL_URL as string);
    } else if (isRegional(userAzureGroups)) {
      window.location.replace(process.env.REACT_APP_REGIONAL_URL as string);
    } else if (isMarketing(userAzureGroups)) {
      window.location.replace(process.env.REACT_APP_MARKETING_URL as string);
    } else if (isCuv(userAzureGroups)) {
      window.location.replace(process.env.REACT_APP_CUV_URL as string);
    }
  }

  return (
    <MaintenanceLayout url={'/maintenance.json'}>
      <OATToast />
    </MaintenanceLayout>
  );
};

export default App;
