import { useEffect, useState } from "react";
import authClient from "../authClient";

const useGetToken = () => {
  const [token, setToken] = useState("");

  useEffect(() => {
    if (!token) {
      (async () => {
        await authClient.getToken().then((res) => {
          setToken(res);
        });
      })();
    }
  }, [token]);

  return token;
};

export default useGetToken;
