import { useToast } from 'oat-common-ui';
import { useEffect, useState } from 'react';
import { fetchAzureGroups } from '../utils/fetchAzureGroups';

const useGetUserAzureGroups = (token: string) => {
  const [userAzureGroups, setUserAzureGroups] = useState<string[]>();
  const { error } = useToast();

  useEffect(() => {
    if (!token) {
      return;
    }

    if (!userAzureGroups?.length) {
      (async () => {
        try {
          const groups = await fetchAzureGroups(token);

          if (groups) {
            setUserAzureGroups(groups);
          }
        } catch (e) {
          error((e as Error).message);
        }
      })();
    }
  }, [token, userAzureGroups, error]);

  return userAzureGroups;
};

export default useGetUserAzureGroups;
